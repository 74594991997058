import { createRef, forwardRef, Suspense, useEffect, useRef, useState } from 'react'
import { Canvas, useFrame } from '@react-three/fiber'
import { Bounds, GizmoHelper, GizmoViewport, OrbitControls, Lightformer, Environment, softShadows, useGLTF, AccumulativeShadows, RandomizedLight, ContactShadows } from '@react-three/drei';
import { Box3 } from 'three';

function Orbit ( props ){
  const moved = useState( false );
  useFrame( state => {
    if( moved.value ) {
      state.performance.regress();
    }
  });
  return <OrbitControls ref={props.entity} dampingFactor={0.1} makeDefault autoRotate={props.autoRotate} rotateSpeed={3} onStart={() => {
    moved.value = true;
  }} onEnd={ e => {
    moved.value = false;
  }}  />
}

const search = window.location.search;

const Model = forwardRef((props, ref) => {
  const name = search.slice( 1 ).split('=')[ 1 ];
  const object = useGLTF('./statics/models/' + name + '.glb');
  const g = new Box3().setFromObject( object.scene );
  const width = Math.abs( g.max.x ) + Math.abs( g.min.x );
  const depth = Math.abs( g.max.z ) + Math.abs( g.min.z );
  const min_y = g.min.y;
  const x = Math.max( width, depth );
  var brightness = 2;

  if( name === '03-3127-20758-07-BLACK' ) {
    brightness = 8
  }
  if( name === '04-3138-19766-07-NAVY' ) {
    brightness = 12;
  }


  object.scene.traverse( child => {
    if( child.isMesh && child.material ) {
      child.material.roughness = 0.8
    }
  });

  return <>
    <Bounds fit clip margin={2}>
      <primitive object={object.scene} ref={ref} />
    </Bounds>
    <Environment resolution={256}>
      <group rotation={[-Math.PI / 2, 0, 0]}>
        <Lightformer castShadow receiveShadow intensity={brightness} rotation-z={-Math.PI / 2} rotation-x={Math.PI/2} position={[0, g.min.z * 1.5, 0]} scale={[x / 4, x / 4, 1]} />
        <Lightformer castShadow receiveShadow intensity={brightness} rotation-z={-Math.PI / 2} rotation-x={Math.PI/2}  position={[0, g.max.z * 1.5, 0]} scale={[x / 4, x / 4, 1]} />
        <Lightformer castShadow receiveShadow intensity={brightness} rotation-y={Math.PI / 2} position={[g.min.x * 4, 0, -g.min.z / 3]} scale={[ x / 4, x / 4, 1]} />
        <Lightformer castShadow receiveShadow intensity={brightness} rotation-y={Math.PI / 2} position={[g.max.x * 1.5, 0, -g.min.z / 3]} scale={[x / 4, x / 4, 1]} />
        <Lightformer castShadow receiveShadow intensity={brightness} rotation-z={Math.PI / 2} position={[0, 0 , g.min.y]} scale={[x / 4, x / 4, 1]} />
        <Lightformer castShadow receiveShadow intensity={brightness} rotation-z={Math.PI / 2} position={[0, 0, g.max.y * 1.5]} scale={[x / 4, x / 4, 1]} />
      </group>
    </Environment>
    <ContactShadows position={[0,min_y - 5,0]} blur={ 1 } far={x } scale={x * 4} 
      resolution={256} opacity={0.5} color="#000000" />
  </>
});

function App() {

  const model = useRef();

  return (
      <Suspense fallback={<span className='loading'>Loading...</span>}>
          <Canvas gl={{ antialias: true }} camera={{ position: [-80, 50, 100], fov: 35, near: 0.1, far: 5000 }}>
            <Model ref={model} />
            <Orbit />
            <GizmoHelper alignment="bottom-right" margin={[80, 80]} renderPriority={0}>
              <GizmoViewport axisColors={["hotpink", "aquamarine", "#3498DB"]} labelColor="black" />
            </GizmoHelper>
            {/* <Stats /> */}
          </Canvas>
        </Suspense>
  );
}

export default App;
